<template>
  <div class="SourceList">
    <Breadcrumbs class="main-top">
      <template slot="action-buttons">
        <v-btn depressed color="primary" to="/applications/create">
          {{ $t("application.Create Application") }}
        </v-btn>
      </template>
    </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>应用列表</span><br />
      管理员可以在当前页面管理已经添加的所有应用，应用可以实现单点登录和数据同步能力。
      <br />
      当添加完应用后，应该确认应用处于启用状态，并已经完成了授权。在应用详情中，可以看到应用的详细信息、单点登录地址、子账户配置、同步配置、授权、审计等信息。
    </v-alert>
    <v-simple-table class="table-wrapper">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              ID
            </th>
            <th class="text-left">
              {{ $t("Application Name") }}
            </th>
            <th class="text-left">
              {{ $t("Application Adapter") }}
            </th>
            <th class="text-left">
              {{ $t("Enabled") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.id" class="text-left">
            <td class="pl-4">{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.service_type }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="item.enabled"
                :loading="item.loading"
                @click.stop="change(item)"
              ></v-switch>
            </td>
            <td class="text-center">
              <v-btn
                icon
                @click.stop="
                  item.service_type === 'WebEXP' ? view(item) : edit(item)
                "
              >
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                :disabled="item.service_type === 'WebEXP'"
                @click.stop="show_info(item)"
              >
                <v-icon>
                  mdi-text-box-search-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                :disabled="item.service_type === 'WebEXP'"
                @click.stop="show_sublist(item.id)"
              >
                <v-icon>
                  mdi-account-multiple-outline
                </v-icon>
              </v-btn>
              <v-btn icon elevation="0" @click.stop="show_log(item)">
                <v-icon>
                  mdi-math-log
                </v-icon>
              </v-btn>
              <v-btn
                v-if="item.service_type === 'WebEXP'"
                icon
                elevation="0"
                color="red"
                disabled="disabled"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                elevation="0"
                color="red"
                :disabled="item.enabled"
                @click.stop="deleteSource(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component
      :is="sheet_editor"
      :source_id="current_id"
      :is_readonly="this.readonly"
      :allDatas="allDatas"
      :curProviderType="curProviderType"
    />
    <ConfirmBox ref="confirm" />
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import JwtEditor from "@/views/application/editor/Jwt";
import CasEditor from "@/views/application/editor/Cas";
import Saml2Editor from "@/views/application/editor/Saml2";
import AliyunRoleEditor from "@/views/application/editor/AliyunRole";
import AliyunUserEditor from "@/views/application/editor/AliyunUser";
import CarsiEditor from "@/views/application/editor/Carsi";
import WebVpnEditor from "@/views/application/editor/WebVpn";
import FormFillEditor from "@/views/application/editor/FormFill";
import Oauth2Editor from "@/views/application/editor/Oauth2";
import OidcEditor from "@/views/application/editor/Oidc";
import LdapEditor from "@/views/application/editor/Ldap";
import RadiusEditor from "@/views/application/editor/Radius";
import WebExpEditor from "@/views/application/editor/WebExp";

const EDITORS = {
  JWT: JwtEditor,
  CAS: CasEditor,
  SAML2: Saml2Editor,
  ALIYUN_RAM_ROLE: AliyunRoleEditor,
  ALIYUN_RAM_USER: AliyunUserEditor,
  CARSI: CarsiEditor,
  WebVPN: WebVpnEditor,
  FORM_FILL: FormFillEditor,
  OAUTH2: Oauth2Editor,
  OIDC: OidcEditor,
  LDAP: LdapEditor,
  RADIUS: RadiusEditor,
  WebEXP: WebExpEditor
};

export default {
  name: "ApplicationList",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      sheet_editor: null,
      current_id: null,
      show_confirm: null,
      readonly: null,
      curProviderType: "",
      sources: [],
      allDatas: {},
      curAppltName: null
    };
  },
  created() {
    this.refreshList();
    bus.$off("refresh").$on("refresh", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  methods: {
    view(item) {
      this.$router.push({
        path: `/applications/view/${item.id}`
      });
    },
    edit(item) {
      this.curId = item.id;
      this.getCurIdData(item.id);
      this.current_id = item.id;
      this.sheet_editor = EDITORS[item.service_type];
      this.readonly = null;
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    show_info(item) {
      this.getCurIdData(item.id);
      this.sheet_editor = EDITORS[item.service_type];
      this.current_id = item.id;
      this.readonly = true;
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    show_sublist(id) {
      this.$router.push({
        path: `/applications/sub_account/${id}`
      });
    },
    show_log(item) {
      this.$router.push({
        path: "/audit/applicationlogin",
        query: {
          id: item.id
        }
      });
    },
    deleteSource({ id, name }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除应用？ #${id} ${name}`,
          callback: () => {
            return this.$http
              .post(`api/application/${id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }

                return "应用已成功删除";
              })
              .catch(({ code, message }) => {
                throw `应用删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    change(item) {
      const index = this.sources.findIndex(_item => {
        return item.id === _item.id;
      });

      item.loading = true;
      const operationText = item.enabled ? "开启" : "关闭";
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要${operationText}应用 ${item.name}？`,
          callback: function() {
            return this.$http
              .post(`api/application/${item.id}/enable`, {
                enable: item.enabled
              })
              .delegateTo(api_request)
              .then(() => {
                return `应用 ${item.name} 已成功${operationText}`;
              })
              .catch(({ code, message }) => {
                throw `应用 ${item.name} ${operationText}失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          item.enabled = !item.enabled;
        })
        .finally(() => {
          this.onPageChange(this.curPage, 10);
          item.loading = false;
          this.$set(this.sources, index, this.sources[index]);
        });
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/application/list?offset=${(curPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          this.sources = res.applications;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    getCurIdData(id) {
      this.$http
        .get(`api/application/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.type = data.service_type;
          this.allDatas = data;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },

  components: {
    Breadcrumbs,
    ConfirmBox,
    JwtEditor,
    CasEditor,
    Saml2Editor,
    AliyunRoleEditor,
    AliyunUserEditor,
    CarsiEditor,
    WebVpnEditor,
    FormFillEditor,
    Oauth2Editor,
    OidcEditor,
    LdapEditor,
    RadiusEditor,
    WebExpEditor
  }
};
</script>

<style lang="less" scoped>
.SourceList {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    margin-top: 20px;
    width: 96%;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
